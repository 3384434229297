<template>
  <div>
    <load-profile></load-profile>
    <n-search-container
      @search="search"
      @reset="reset"
      :fields="searchFields"
      :loading="loading"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
      </n-search-input>
    </n-search-container>

    <b-card no-body class="mb-0">
      <div class="m-1">
        <b-row>
          <b-col>
            <label>{{ $t("field.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
          <b-col cols="auto" class="text-right">
            <b-row align-h="end">
              <b-col class="px-0" cols="auto">
                <n-column-visibility
                  :fields="fields"
                  :visible-columns="visibleColumns"
                  v-model="visibleColumns"
                  @change="key++"
                ></n-column-visibility>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          ref="table"
          :key="`table-${key}`"
          @sort-changed="sortChanged"
          :visible-columns="visibleColumns"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="query.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
          :resource="resource"
          :route="route"
          :viewable="true"
          :updatable="false"
        >
          <template #cell(region)="data">
            <span v-if="data.item.region">
              {{ data.item.region.regionCode }}
            </span>
          </template>
          <template #cell(agent)="data">
            <span v-if="data.item.agent">
              {{ data.item.agent.agentCode }}
            </span>
          </template>
          <template #cell(salesperson)="data">
            <span v-if="data.item.salesperson">
              {{ data.item.salesperson.name }}
              <small class="d-block text-primary">
                {{ data.item.salesperson.idCard }}
              </small>
            </span>
          </template>
          <template #cell(outlet)="data">
            <span v-if="data.item.outlet">
              {{ trans(data.item.outlet, "name", $i18n.locale) }}
              <small class="d-block text-primary">
                {{ data.item.outlet.outletCode }}
              </small>
            </span>
          </template>
          <template #cell(subTotal)="data">
            <span class="font-weight-bold">
              {{ data.item.subTotal | currency }}
            </span>
          </template>
          <template #cell(amountOff)="data">
            <span
              class="font-weight-bold text-negative"
              v-if="data.item.amountOff"
            >
              {{ data.item.amountOff | currency }}
            </span>
            <span v-else> - </span>
          </template>
          <template #cell(total)="data">
            <span class="font-weight-bold text-primary">
              {{ data.item.total | currency }}
            </span>
          </template>
          <template #cell(state)="data">
            <b-badge pill badge-glow :class="data.item.state">
              {{ $t(`orderState.${data.item.state}`) }}
            </b-badge>
          </template>
          <template #cell(paymentMethod)="data">
            <span
              v-if="data.item.paymentMethod"
              :class="{
                'text-primary': data.item.paymentMethod.code == 'vattanac',
              }"
            >
              {{ trans(data.item.paymentMethod, "name", $i18n.locale) }}
            </span>
          </template>
          <template #cell(expectDeliveryDate)="data">
            {{ data.item.expectDeliveryDate | formatDate("LL") }}
          </template>
          <template #back-button="data">
            <b-button
              v-b-tooltip.hover
              :title="$t('button.log')"
              variant="primary"
              pill
              size="sm"
              @click="showRemark(data.item)"
            >
              <feather-icon icon="MessageCircleIcon" />
            </b-button>
          </template>
        </n-table>
        <n-pagination
          ref="pagination"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="query.page"
          @change="changePage"
        ></n-pagination>
      </div>
    </b-card>

    <remark-modal ref="remarkModal" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BAvatar,
  BBadge,
  BFormGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NTable from "@/components/NTable";
import TableFields from "./tableFields";
import SearchInputs from "./searchInput";
import NAsyncSingleSelect from "@/components/NAsyncSingleSelect";
import NColumnVisibility from "@/components/NColumnVisibility";
import RemarkModal from "./remark/RemarkModal.vue";

const OrderRepository = Repository.get("order");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BBadge,
    BFormGroup,

    NPagination,
    NSearchContainer,
    NSearchInput,
    NTable,
    vSelect,
    NAsyncSingleSelect,
    NColumnVisibility,
    RemarkModal,
  },
  watch: {
    perPage() {
      this.changePage();
      this.$refs.pagination.reset();
    },
  },
  computed: {
    visibleColumns: {
      get() {
        return this.$store.state.column.order;
      },
      set(value) {
        this.$store.commit("column/SET_ORDER_COLUMN", value);
      },
    },
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },
  data() {
    return {
      key: 1,
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        regionId: this.$route.query.regionId || [],
        agentId: this.$route.query.agentId || [],
        outletId: this.$route.query.outletId || [],
        orderCode: this.$route.query.orderCode || null,
        outletSegmentId: this.$route.query.outletSegmentId || [],
        channel: this.$route.query.channel || [],
        paymentMethodId: this.$route.query.paymentMethodId || [],
        state: this.$route.query.state || [],
        createdAt: this.$route.query.createdAt || [],
        expectDeliveryDate: this.$route.query.expectDeliveryDate || [],
      },
      query: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        regionId: this.$route.query.regionId || [],
        agentId: this.$route.query.agentId || [],
        outletId: this.$route.query.outletId || [],
        orderCode: this.$route.query.orderCode || null,
        outletSegmentId: this.$route.query.outletSegmentId || [],
        channel: this.$route.query.channel || [],
        paymentMethodId: this.$route.query.paymentMethodId || [],
        state: this.$route.query.state || [],
        createdAt: this.$route.query.createdAt || [],
        expectDeliveryDate: this.$route.query.expectDeliveryDate || [],
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    showRemark(item) {
      this.$refs.remarkModal.show(item);
    },
    search() {
      this.query.page = 1;
      this.$refs.pagination.reset();
      this.getData();
    },
    reset() {
      this.$refs.search.reset();
      this.search();
    },
    getData() {
      this.loading = true;
      this.query = {
        ...this.query,
        ...this.params,
      };
      this.updateQuerySting();

      const params = {
        ...this.query,
        searchFields: this.searchFields,
        query: [
          {
            searchby: "type",
            searchoperator: "=",
            search: 1,
          },
        ],
      };
      OrderRepository.index(params)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data.list];
            this.total = data.total;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const fields = TableFields;
    const searchFields = SearchInputs;
    const resource = "order";
    const route = "order";

    return {
      fields,
      searchFields,
      resource,
      route,
    };
  },
};
</script>
