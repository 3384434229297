export default [
  {
    key: 'increasement',
    sortable: true,
    sortField: 'id',
    label: '#',
    hideToggle: true,
    thClass: 'w-50px text-center',
    tdClass: 'text-center',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'region',
    label: 'field.region',
    sortable: true,
    sortField: 'regionId',
  },
  {
    key: 'agent',
    label: 'field.agent',
    sortable: true,
    sortField: 'agentId',
    hideToggle: true,
  },
  {
    key: 'outlet',
    label: 'field.outlet',
    sortable: true,
    sortField: 'outletId',
    hideToggle: true,
  },
  {
    key: 'orderCode',
    label: 'field.orderCode',
    sortable: true,
    sortField: 'orderCode',
    hideToggle: true,
  },
  {
    key: 'subTotal',
    label: 'field.subTotal',
    sortable: true,
    sortField: 'total',
  },
  {
    key: 'amountOff',
    label: 'field.discount',
    sortable: true,
    sortField: 'total',
  },
  {
    key: 'total',
    label: 'field.total',
    sortable: true,
    sortField: 'total',
    hideToggle: true,
  },
  {
    key: 'state',
    label: 'field.state',
    sortable: true,
    sortField: 'state',
    thClass: 'text-center',
    tdClass: 'text-center',
    hideToggle: true,
  },
  {
    key: 'paymentMethod',
    label: 'field.paymentMethod',
    sortable: true,
    sortField: 'paymentMethod',
    hideToggle: true,
  },
  {
    key: 'createdAt',
    sortable: true,
    sortField: 'id',
    label: 'field.orderedDate',
    hideToggle: true,
  },
  {
    key: 'expectDeliveryDate',
    sortable: true,
    sortField: 'expectDeliveryDate',
    label: 'field.expectDeliveryDate',
    hideToggle: true,
  },
  {
    key: 'salesperson',
    label: 'field.salesperson',
    hideToggle: true,
  },
  {
    key: 'actions',
    label: 'field.action',
    hideToggle: true,
    thClass: 'text-center',
    tdClass: 'text-center',
  },
];
